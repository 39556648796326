/*
  General CSS
*/

body {
  font-family: 'Josefin Slab', serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}