.mainHero {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../../Images/Main/midamHeroImg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 25vw;
    margin-top: 50px;
}

.logoBackground {
    background-color: white;
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 20%;
    left: 15%;
}

.mainLogo {
    width: 15vw;
}

@media (max-width: 768px) {
    .mainHero {
        height: 40vw;
        margin-top: 0;
    }

    .logoBackground {
        height: 20vw;
        width: 20vw;
        top: 20%;
        left: 15%;
    }

    .mainLogo {
        width: 20vw;
    }
}

@media (max-width: 600px) {
    .mainHero {
        height: 60vw;
        margin-top: 0;
    }

    .logoBackground {
        height: 25vw;
        width: 25vw;
        top: 25%;
        left: 10%;
    }

    .mainLogo {
        width: 25vw;
    }
}