.mainHeader {
  width: 100%;
  height: 50px;
  background-color: #1e1e1e;
  display: flex;
  justify-content: right;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 5;
}

.mainHeaderUl {
  display: flex;
  gap: 20px;
  padding: 10px 20px;
}

.mainHeaderLi {
  list-style: none;
}

.mainHeaderSpan {
  display: flex;
  align-items: center;
  gap: 4px;
}

.mainHeaderLi a {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.mainHeaderLi img {
  color: white;
  height: 14px;
  width: auto;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {

  .mainHeaderUl {
    gap: 20px;
    padding: 10px 20px;
  }

  .mainHeaderSpan {
    gap: 4px;
  }

  .mainHeaderLi a {
    font-size: 14px;
  }

  .mainHeaderLi img {
    height: 14px;
  }

  .HeaderMenu {
    display: none;
  }
}

@media screen and (max-width: 767px) {
    .mainHeaderUl{
        display: none;
    }

    .mainHeader {
        background-color: transparent;
    }
}
