footer {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 5vw;
    padding-bottom: 20px;
}

.blackLine {
    border-bottom: 0.5px black solid;
    width: 20vw;
    height: 1px;
    opacity: 25%;
}

footer a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

footer h4 {
    font-size: 1.5vw;
    letter-spacing: 1px;
    width: 40vw;
    text-align: center;
}

@media (min-width: 1366px) {
    .blackLine {
        width: 40vw;
    }

    footer h4 {
        font-size: 12px;
        letter-spacing: 1px;
        width: 40vw;
        text-align: center;
    }
}

@media (max-width: 1365px) and (min-width: 768px) {
    .blackLine {
        width: 40vw;
    }

    footer h4 {
        font-size: 10px;
        letter-spacing: 1px;
        width: 40vw;
        text-align: center;
    }
}