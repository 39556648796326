/*
    Location and Contacts
*/

.locationSection {
    background-color: white;
    padding: 50px 0;
    width: 80%;
    margin: 0 auto;
}

.locationHeading {
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: 500;
    padding-bottom: 15px;
}

.locationMap {
    width: 100%;
    height: 400px;
    border: 0;
}

.contactDivider {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.contactInfo {
    padding: 0 50px 50px 50px;
    border-right: 1px black solid;
}

.contactInfoHeading {
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 32px;
    padding-bottom: 60px;
}

.contactInfoList {
    font-size: 15px;
    font-weight: 400;
    padding-bottom: 20px;
}

@media (max-width: 768px) {
    .locationSection {
        padding: 50px 0;
    }

    .locationHeading {
        font-size: 32px;
    }

    .locationMap {
        height: 250px;
    }

    .contactDivider {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .contactInfo {
        border-right: none;
        padding: 0;
    }

    .contactInfoHeading {
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 32px;
        padding-bottom: 30px;
    }

    .contactInfoList {
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 15px;
    }
}