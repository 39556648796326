/*
    CSS for Menu
*/

.Menu main {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*
    Menu Heading
*/

.MenuHeadingContainer {
    display: flex;
    justify-content: center;
    gap: 5px;
    padding-bottom: 28px;
}

.MenuHeading {
    font-size: 40px;
    font-weight: 700;
}

.MenuHeadingIcon {
    width: 30px;
    height: 30px;
}

.MainMenuContainer {
    padding-bottom: 20px;
}
