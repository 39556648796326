/*
    CSS for Menu Item Lists
*/

.MenuItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MenuItemList, .MenuItemListSelected {
    padding: 5px 20px 5px 20px;
    cursor: pointer;
    display: inline-block;
}

.MenuItemList {
    margin-bottom: 36px;
}

.MenuItemType {
    font-size: 20px;
    font-weight: 600;
}

.MenuItemListSelected{
    margin-bottom: 23px;
    border-radius: 15px;
    border: 1px solid #979797;
    color: #979797;
}

.categoryItemsDefault {
    display: none;
    transition: all 0.3s;
}

.categoryItems {
    margin-bottom: 36px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 13px;
    column-gap: 26px;
}

.menuSecondaryHeader {
    letter-spacing: 2px;
    font-size: 14px;
    text-transform: capitalize;
    color: #979797;
    padding-bottom: 20px;
}

.menuSecondaryHeaderHidden {
    display: none;
}

/*
    CSS for individual menu items
*/

.MenuTypeComponents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 152px;
}

.MTCImage {
    width: 152px;
    height: 152px;
    flex-shrink: 0;
    border-radius: 15px;
    margin-bottom: 8px;
}

.MTCHeading, .MTCPrice {
    font-size: 15px;
    text-align: center;
    line-height: 18px;
}

.MTCPrice {
    font-style: italic;
}

.HSPlatter {
    width: 70vw;
    border-radius: 15px;
    height: auto;
    margin-bottom: 15px;
}

.hidden {
    display: none;
}

.HSPlatterPrice {
    font-size: 18px;
    color: #979797;
    padding-bottom: 10px;
    font-weight: 500;
}

.HSPlatterOptionList {
    list-style: decimal;
    padding-bottom: 10px;
}

.HSPlatterOptions :last-child {
    padding-bottom: 20px;
}

.HSOptionList {
    list-style: upper-alpha;
    padding-bottom: 10px;
}

.iconContainer {
    position: relative;
    bottom: 138px;
    left: 55px;
    overflow: visible;
    margin: -12px;
}

.HSPlatterSpIconContainer {
    display: flex;
    gap: 5px;
    align-items: center;
}

.HSPlatterIcon{
    padding-bottom: 10px !important;
}

@media (min-width: 768px){
    .HSPlatter {
        width: 35vw;
    }
}